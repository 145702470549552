.apexcharts-canvas svg {
    overflow: visible;
}

.apexcharts-canvas svg foreignObject {
    overflow: visible;
}

.apexcharts-tooltip {
    color: white;
}

.apexcharts-tooltip .apexcharts-tooltip-series-group.active {
    background: #ffffff !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid #e3e3e3 !important;
    background: #2B3243 !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #495373 !important;
    border-bottom: 1px solid #ddd;
}

.apexcharts-zoom-icon svg{
    fill: #4E55C3 !important;
  }
.apexcharts-zoomin-icon svg {
    fill: #4E55C3 !important;
  }
  .apexcharts-zoomout-icon svg {
    fill: #4E55C3 !important;
  }
  .apexcharts-reset-icon svg {
    fill: #4E55C3 !important;
  }
.apexcharts-menu-icon svg {
  fill: #4E55C3 !important;
}